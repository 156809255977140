.MuiFormLabel-root.MuiInputLabel-root.add-customer-form-input-label-root {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0.75rem;
}

.MuiFormLabel-root.MuiInputLabel-root.add-customer-form-input-label-root:not(.Mui-error) {
  color: var(--color-primary-dark);
}

.add-customer-form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.add-customer-form-actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding-bottom: 1rem;
}

.add-customer-form-actions-wrapper.center-align {
  justify-content: center;
}

.device-registration-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  background-color: var(--color-primary-light);
  border-radius: 4px;
}

.device-registration-screen-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
}

.device-registration-table-wrapper {
  flex-grow: 1;
  height: 0;
}