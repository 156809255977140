.MuiListItem-root.sidebar-list-item {
  border-radius: 5px;
  margin-top: 0.5rem;
}

.MuiListItem-root.sidebar-list-item:first-child {
  margin-top: 0;
}

.MuiListItem-root.sidebar-list-item .MuiButtonBase-root.MuiListItemButton-root {
  border-radius: 5px;
}

.MuiListItem-root.sidebar-list-item.active {
  background-color: var(--color-primary-main);
}

.MuiListItem-root.sidebar-list-item.child {
  font-size: 14px;
}

.MuiListItem-root.sidebar-list-item.child::before {
  position: absolute;
  top: calc(50% - 2px);
  right: calc(100% + 3px);
  width: 10px;
  height: 2px;
  margin: auto;
  content: '';
  background-color: #5B4DBE52;
}

.MuiListItem-root.sidebar-list-item.child::after {
  position: absolute;
  top: -0.5rem;
  bottom: 0;
  right: calc(100% + 13px);
  width: 2px;
  height: calc(100% + 0.5rem);
  content: '';
  background-color: #5B4DBE52;
}

.MuiListItem-root.sidebar-list-item.child:first-child::after {
  top: 0;
  height: 100%;
}

.MuiListItem-root.sidebar-list-item.child:last-child::after {
  height: calc(50% + 0.5rem);
}

.MuiToolbar-root.page-layout-toolbar {
  align-items: center;
  justify-content: center;
  padding: 1rem 0.5rem 0.5rem;
}

.appbar-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.5rem; */
  border-left: 1px solid #00000026;
}

.mobile-drawer-close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}

.gate-control-actions-wrapper {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.gate-control-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.gate-control-button.open {
  background-color: #2B64AF;
}

.gate-control-button.close {
  background-color: #EF5350;
}

.device-status-section-wrapper {
  margin-top: 3rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.device-status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.device-status-icon {
  width: 28px;
  height: 28px;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
}

.device-status-icon.power {
  border-color: var(--color-error-main);
  background-color: var(--color-error-main);
}

.device-status-icon.camera {
  border-color: var(--color-success-main);
  background-color: var(--color-success-main);
}

.device-status-icon.gpu {
  border-color: var(--color-warning-main);
}

.device-status-icon.network {
  border-color: var(--color-info-main);
  background-color: var(--color-info-main);
}

.header-avatar-wrapper {
  display: flex;
  align-items: center;
  padding: 0.35rem 0.35rem 0.35rem 1rem;
  background-color: var(--color-primary-light);
  gap: 0.6rem;
  color: var(--color-primary-main);
  border-radius: 50px;
  cursor: pointer;
}

.show-notification-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-light);
  border-radius: 3px;
  padding: 0.25rem;
  cursor: pointer;
}

.dashboard-time-range-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-info-light);
  border-radius: 3px;
  padding: 0.25rem;
  cursor: pointer;
}

.header-actions-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.screen-content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.screen-main-component {
  flex-grow: 1;
  padding: 0.5rem;
  background-color: #f2f1fb;
  height: 0;
  overflow-y: auto;
  margin: 0 0.75rem 0.75rem 0;
  border-radius: 6px;
  border: 1px solid #f2f1fb;
}

.screen-outlet-wrapper {
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

@media(min-width: 992px) {
  .appbar-content-wrapper {
    justify-content: flex-end;
  }

  .mobile-drawer-close-icon {
    display: none;
  }

  .screen-outlet-wrapper {
    padding: 0.5rem;
  }
}