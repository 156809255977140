.login-component-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.login-content-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.login-image-section {
  /* height: 100%; */
  flex: 0.55;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  padding: 3rem;
}

.login-form-section {
  height: 100%;
  flex: 0.45;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 4rem;
  gap: 2rem;
}

.login-form-background {
  position: absolute;
  left: 0;
  height: 100vh;
  width: 50vw;
  background-color: #8D86C3;
  z-index: -1;
}

.login-form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.login-input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: #F4F4FE;
}

.MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.login-input-root,
.MuiFormControl-root .MuiInputBase-root.MuiFilledInput-root.login-input-root input {
  border-radius: 4px;
  background-color: white;
}