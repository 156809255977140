.confirmation-dialog-title-root {
  background-color: var(--color-primary-main);
  position: relative;
}

.confirmation-dialog-title-root.error {
  background-color: var(--color-error-main);
}

.confirmation-dialog-title-root.success {
  background-color: var(--color-success-main);
}

.confirmation-dialog-content-root {
  padding-top: 20px !important;
}

.confirmation-input-section-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
}

.confirmation-input-wrapper {
  width: 100%;
  max-width: 300px;
}

.confirmation-dialog-actions-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.confirmation-info-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  color: #474747;
  gap: 0.25rem;
}