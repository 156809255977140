@import url('../screen.css');

.user-status {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-status-wrapper.Active {
  background-color: var(--color-success-light);
  color: var(--color-success-main);
  fill: var(--color-success-main);
}

.user-status-wrapper.Frequent {
  background-color: var(--color-warning-light);
  color: var(--color-warning-main);
  fill: var(--color-warning-main);
}

.user-status-wrapper.Inactive {
  background-color: var(--color-error-light);
  color: var(--color-error-main);
  fill: var(--color-error-main);
}

.users-table-row-actions {
  color: var(--color-info-dark);
}

/* USER DETAILS DIALOG */
.MuiFormLabel-root.MuiInputLabel-root.user-form-input-label-root {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.MuiFormLabel-root.MuiInputLabel-root.user-form-input-label-root:not(.Mui-error) {
  color: #424242;
}

.MuiInputBase-root.MuiFilledInput-root.filled-input-root.success {
  background-color: var(--color-success-light);
  color: var(--color-success-main);
}

.MuiInputBase-root.MuiFilledInput-root.filled-input-root.success .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconFilled {
  color: var(--color-success-main);
}

.MuiInputBase-root.MuiFilledInput-root.filled-input-root.error {
  background-color: var(--color-error-light);
  color: var(--color-error-main);
}

.MuiInputBase-root.MuiFilledInput-root.filled-input-root.error .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconFilled {
  color: var(--color-error-main);
}

.MuiInputBase-root.MuiFilledInput-root.filled-input-root.warning {
  background-color: var(--color-warning-light);
  color: var(--color-warning-main);
}

.MuiInputBase-root.MuiFilledInput-root.filled-input-root.warning .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconFilled {
  color: var(--color-warning-main);
}