@import url('../screen.css');

.customer-status {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-status-wrapper.Active {
  background-color: var(--color-success-light);
  color: var(--color-success-main);
  fill: var(--color-success-main);
}

.customer-status-wrapper.Renewal {
  background-color: var(--color-warning-light);
  color: var(--color-warning-main);
  fill: var(--color-warning-main);
}

.customer-status-wrapper.Inactive {
  background-color: var(--color-error-light);
  color: var(--color-error-main);
  fill: var(--color-error-main);
}

.counts-cell-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.customer-table-row-actions {
  color: var(--color-info-dark);
}

.customer-details-dialog-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.customer-details-tabs-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: var(--color-primary-light);
  padding: 0.5rem;
  border-radius: 4px;
  flex-wrap: nowrap;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.customer-details-tabs-wrapper::-webkit-scrollbar {
  display: none;
}

.MuiFormLabel-root.MuiInputLabel-root.customer-details-form-input-label-root {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.MuiFormLabel-root.MuiInputLabel-root.customer-details-form-input-label-root:not(.Mui-error) {
  color: #424242;
}

.customer-subscription-status-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid;
  border-radius: 5px;
  flex-grow: 1;
  padding: 0 0.5rem;
}

.customer-subscription-status-input.Renewal {
  border-color: var(--color-warning-main);
  background-color: var(--color-warning-light);
  color: var(--color-warning-main);
  fill: var(--color-warning-main);
}

.customer-subscription-status-input.Active {
  border-color: var(--color-success-main);
  background-color: var(--color-success-light);
  color: var(--color-success-main);
  fill: var(--color-success-main);
}

.customer-subscription-status-input.Inactive {
  border-color: var(--color-error-main);
  background-color: var(--color-error-light);
  color: var(--color-error-main);
  fill: var(--color-error-main);
}

.customer-subscription-status-input .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer-details-dialog-actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.customer-details-contacts-tab-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.customer-details-contacts-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-primary-light);
  border-radius: 4px;
  padding: 0.5rem;
  padding-left: 1rem;
  color: var(--color-primary-main);
}

.society-configuration-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.society-configuration-tabs-wrapper {
  width: 100%;
  background-color: var(--color-primary-light);
  padding: 0.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* CUSTOMER DETAILS DIALOG - REPORTING TAB */
.reporting-tab-wrapper {
  flex-grow: 1;
  background-color: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reporting-tab-actions-wrapper {
  width: 100%;
  padding: 0.5rem;
  background-color: #00000005;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reporting-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  max-width: 300px;
}

.reporting-table-actions-column-cell {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}