.screen-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.screen-stats-wrapper {
  display: flex;
  gap: 1rem;
}

.total-count-wrapper {
  background-color: white;
  border-radius: 4px;
  flex: 0.25;
}

.count-wrapper {
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
  position: relative;
}

.count-wrapper.divider-before::before,
.count-wrapper.divider-after::after {
  content: "";
  position: absolute;
  height: 60%;
  width: 2px;
  background-color: #00000026;
}

.count-wrapper.divider-before::before {
  left: 0;
}

.count-wrapper.divider-after::after {
  right: 0;
}

.count-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 0.5rem;
  background-color: var(--color-primary-light);
  color: var(--color-primary-main);
  fill: var(--color-primary-main);
}

.count-icon-wrapper.success {
  background-color: var(--color-success-light);
  color: var(--color-success-main);
  fill: var(--color-success-main);
}

.count-icon-wrapper.warning {
  background-color: var(--color-warning-light);
  color: var(--color-warning-main);
  fill: var(--color-warning-main);
}

.count-icon-wrapper.error {
  background-color: var(--color-error-light);
  color: var(--color-error-main);
  fill: var(--color-error-main);
}

.category-count-wrapper {
  flex: 0.75;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 4px;
}

.table-actions-wrapper {
  padding: 1rem;
  background-color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MuiFormControl-root .MuiInputBase-root.table-search-input {
  border-radius: 4px;
  min-width: 300px;
  background-color: #F3F3F3;
}

.MuiFormControl-root .MuiInputBase-root.table-search-input .MuiInputBase-input::placeholder,
.MuiFormControl-root .MuiInputBase-root.table-search-input .MuiInputBase-input::-webkit-input-placeholder {
  color: #00000061;
}

.table-wrapper {
  background-color: white;
  border-radius: 4px;
  flex-grow: 1;
  height: 0;
  display: flex;
  flex-direction: column;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator.table-column-separator {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.table-column-header:not(:last-child):not(.hide-last-separator):after,
.MuiDataGrid-root .MuiDataGrid-columnHeader.table-column-header:not(:last-child).hide-last-separator:not(:nth-last-child(2))::after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  height: 60%;
  background-color: #00000026;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader.table-column-header {
  background-color: #D7D1FF;
}

.MuiDataGrid-root.table-root {
  border-radius: 5px;
}

.MuiDataGrid-root .MuiDataGrid-virtualScroller.table-virtual-scroller {
  min-height: 36px;
}