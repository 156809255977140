@import url('../screen.css');

.system-integrators-table-row-actions {
  color: var(--color-info-dark);
}

.dialog-tab-selection-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: var(--color-primary-light);
  padding: 0.5rem;
  border-radius: 6px;
}

.system-integrator-contact-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-primary-light);
  padding: 0.5rem;
  padding-left: 1rem;
  border-radius: 6px;
}

.upload-support-logo-drop-area-wrapper {
  width: 100%;
  /* height: 100%; */
  /* min-height: 40vh; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  border: 2px dashed #808080;
  cursor: pointer;
  gap: 1rem;
  padding: 0.5rem;
}